import { uuid4 } from '@sentry/utils';
import { ID_YANDEX_METRIKA } from 'analytics/const';
import cookies from 'js-cookie';

import { externalServicesUrls } from 'constants/externalServicesUrls';

import { isBrowser, isTestEnvironment } from 'utils/environment';
import { fetchWrapper } from 'utils/fetchWrapper';

import { EHost, IAnalyticsEvent, IAnalyticsEventCoreData } from './types';

interface IGoogleAnalytics {
  action: string;
  name?: string;
  category?: string;
  label?: string;
}

interface IYandexAnalytics {
  action: string;
  goalParams: {
    user_id: string; // user uuid
    [key: string]: string | number | boolean;
  };
}

export const sendGoogleAnalytics = ({
  action,
  name,
  category,
  label,
}: IGoogleAnalytics): void => {
  const eventObject = {
    hitType: 'event',
    event: name,
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  };

  if (isBrowser && typeof window.ga === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.ga('send', eventObject);
    // eslint-disable-next-line no-console
    console.info('Send ga event', eventObject);
  }
};

export const sendYandexAnalytics = ({
  action,
  goalParams,
}: IYandexAnalytics): void => {
  if (isBrowser && typeof window.ym === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.ym(ID_YANDEX_METRIKA, 'reachGoal', action, goalParams);
    // eslint-disable-next-line no-console
    console.info('Send ya event', action, goalParams);
  }
};

//Этот метод отправляет аналитику в ClickHouse
export const sendSelfpubAnalytics = async (
  params: IAnalyticsEventCoreData,
): Promise<void> => {
  const event: IAnalyticsEvent = {
    ...params,
    event_id: uuid4(),
    event_date: Date.now().toString(),
    host: EHost.web,
    source: {
      debug: isTestEnvironment(),
    },
    varioqube_ab_variants: [123],
  };

  try {
    const sessionId = cookies.get('sessionid');

    await fetchWrapper(externalServicesUrls.selfpubAnalytics, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Selfpub-Session-Id': sessionId ?? 'NO_SESSION_ID',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'SELFPUB-CLIENT-KEY': '06d84398-caa0-4001-a6a6-d41df1dc2b6a',
      },
      body: JSON.stringify(event),
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to send analytics event:', error);
  }
};
